import {SeatingPlan} from '@wix/ambassador-seating-v1-seating-plan/types'
import {PlaceWithTicketInfo, getEventId, getPricingOptions, hasPricingOptions} from '@wix/wix-events-commons-statics'
import {getEvent} from '../../selectors/event'
import {getPlaceWithTicketInfo, isPlaceArea, pricingOptionPlaceId} from '../../selectors/seating/place'
import {createAsyncAction} from '../../services/redux-toolkit'

export const describeSeatingPlan = createAsyncAction<{plan: SeatingPlan; places: PlaceWithTicketInfo[]}, void>(
  'DESCRIBE_SEATING_PLAN',
  async (_, {getState, extra: {serverApi}}) => {
    const state = getState()
    const event = getEvent(state)
    const {tickets} = state

    const response = await serverApi.describeSeatingPlan(getEventId(event))

    const {
      plan,
      plan: {sections},
      seatingReservationsSummary,
    } = response

    const categories = response.plan.categories.filter(category => category.totalCapacity)

    const uncategorizedPlaces = response.plan.uncategorizedPlaces
      .filter(place => place.capacity)
      .map(place =>
        getPlaceWithTicketInfo({
          place,
          category: null,
          sections,
          tickets,
          seatingReservationSummary: seatingReservationsSummary,
        }),
      )

    const placesWithInfo: PlaceWithTicketInfo[] = [
      ...categories.reduce((places, category) => {
        const {single, whole} = category.places.reduce(
          (acc, place) => {
            if (!place.capacity) {
              return acc
            }

            const [sectorId, elementId] = place.id.split('-')
            const section = sections.find(item => item.id === Number(sectorId))
            const element = section.elements.find(item => item.id === Number(elementId))
            const placeWithTicketInfo = getPlaceWithTicketInfo({
              place,
              category,
              sections,
              tickets,
              seatingReservationSummary: seatingReservationsSummary,
            })

            const ticket = placeWithTicketInfo.ticket

            if (element.reservationOptions?.reserveWholeElement) {
              const added = acc.whole.find(
                wholeResElement =>
                  `${wholeResElement.sectorId}-${wholeResElement.elementId}` ===
                  `${placeWithTicketInfo.sectorId}-${placeWithTicketInfo.elementId}`,
              )

              if (added) {
                added.places.push(placeWithTicketInfo)
                added.capacity += placeWithTicketInfo.capacity
              } else {
                acc.whole.push({...placeWithTicketInfo, places: [placeWithTicketInfo]})
              }
            } else if (ticket && hasPricingOptions(ticket) && isPlaceArea(place)) {
              const pricingOptions = getPricingOptions(ticket)
              pricingOptions.forEach(({id: pricingOptionId}) => {
                acc.single.push({
                  ...placeWithTicketInfo,
                  id: pricingOptionPlaceId(placeWithTicketInfo.id, pricingOptionId),
                  pricingOptionId,
                })
              })
            } else {
              acc.single.push(placeWithTicketInfo)
            }

            return acc
          },
          {single: [], whole: []} as {single: PlaceWithTicketInfo[]; whole: PlaceWithTicketInfo[]},
        )

        return [...places, ...single, ...whole]
      }, [] as PlaceWithTicketInfo[]),
      ...uncategorizedPlaces,
    ]

    return {
      plan,
      places: placesWithInfo,
    }
  },
)
